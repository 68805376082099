/* src/components/PageContainer.module.css */
.pageContainer {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  
  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: transform 0.5s ease-in-out;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);  /* Adjust opacity as needed */
  }
  
  .content {
    position: relative;
    z-index: 10;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 20px;
  }
  
  /* Desktop styles */
  @media (min-width: 769px) {
    .background {
      width: 300%;
      background-image: url('https://i.imgur.com/E5PT8VH.png');
    }
  
    .landingPage .background { transform: translateX(0); }
    .signInPage .background { transform: translateX(-33.33%); }
    .signUpPage .background,
    .welcomeDashboard .background,
    .surveyDashboard .background { transform: translateX(-66.66%); }
  }
  
  /* Mobile styles */
  @media (max-width: 768px) {
    .landingPage .background { background-image: url('https://i.imgur.com/VYcPlvp.png'); }
    .signInPage .background { background-image: url('https://i.imgur.com/4wf137T.png'); }
    .signUpPage .background,
    .welcomeDashboard .background,
    .surveyDashboard .background { background-image: url('https://i.imgur.com/YQpwFSR.png'); }
  }
  
  /* Add any additional styles for buttons, forms, etc. here */
  .button {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: rgba(255, 255, 255, 0.2);
    border: 2px solid white;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
  
  .input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 25px;
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
  }
  
  .input::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }