.sliderContainer {
  width: 100%;
  margin: 20px 0;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.2);
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #3498db;
  cursor: pointer;
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.3);
}

.slider::-moz-range-thumb {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #3498db;
  cursor: pointer;
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.3);
}

.sliderLabels {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.sliderLabel {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
}

/* Add a filled part to the slider to show progress */
.slider::-webkit-slider-runnable-track {
  background: linear-gradient(to right, #3498db 0%, #3498db var(--progress), rgba(255, 255, 255, 0.2) var(--progress), rgba(255, 255, 255, 0.2) 100%);
}

.slider::-moz-range-track {
  background: linear-gradient(to right, #3498db 0%, #3498db var(--progress), rgba(255, 255, 255, 0.2) var(--progress), rgba(255, 255, 255, 0.2) 100%);
}