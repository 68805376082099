.surveyDashboardContent {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: white;
}

.surveyDashboardContent h1 {
  text-align: center;
  margin-bottom: 20px;
}

.signOutLink {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 12px;
  text-decoration: none;
  color: white;
}

.signOutLink:hover {
  text-decoration: underline;
}

.versionInfo {
  background-color: rgba(248, 215, 218, 0.7);
  color: #721c24;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
}

.overallProgress {
  margin-bottom: 20px;
}

.progressBar {
  width: 100%;
  height: 10px;
  background-color: rgba(236, 240, 241, 0.3);
  border-radius: 5px;
  overflow: hidden;
}

.progressFill {
  height: 100%;
  background-color: #3498db;
  transition: width 0.3s ease-in-out;
}

.overallProgress p {
  text-align: center;
  margin-top: 10px;
}

.surveyList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.surveyButton {
  padding: 10px 20px;
  background-color: rgba(52, 152, 219, 0.7);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.surveyButton:hover {
  background-color: rgba(41, 128, 185, 0.7);
}

.surveyButton.completed {
  background-color: rgba(46, 204, 113, 0.7);
}

.actionPlanButton {
  display: block;
  width: 100%;
  padding: 15px;
  background-color: rgba(211, 84, 0, 0.7);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1.1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
}

.actionPlanButton:hover {
  background-color: rgba(230, 126, 34, 0.7);
}

.actionPlanButton:disabled {
  background-color: rgba(149, 165, 166, 0.7);
  cursor: not-allowed;
}

.surveyForm {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 8px;
}

.surveyForm h2 {
  margin-bottom: 20px;
}

.questionContainer {
  margin-bottom: 20px;
}

.questionLabel {
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
}

.navigationButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.navigationButtons button,
.resetButton {
  padding: 10px 20px;
  background-color: rgba(52, 152, 219, 0.7);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.navigationButtons button:hover,
.resetButton:hover {
  background-color: rgba(41, 128, 185, 0.7);
}

.navigationButtons button:disabled {
  background-color: rgba(189, 195, 199, 0.7);
  cursor: not-allowed;
}

.error {
  color: #e74c3c;
  font-size: 0.9em;
  margin-top: 5px;
}

/* Styles for form inputs */
input[type="text"],
input[type="number"],
textarea,
select {
  width: 100%;
  padding: 10px;
  border: 1px solid rgba(189, 195, 199, 0.7);
  border-radius: 4px;
  font-size: 1em;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}

input[type="radio"],
input[type="checkbox"] {
  margin-right: 10px;
}

.resetButton {
  margin-top: 20px;
  background-color: rgba(231, 76, 60, 0.7);
}

.resetButton:hover {
  background-color: rgba(192, 57, 43, 0.7);
}

.footer {
  margin-top: 40px;
  padding: 20px;
  background-color: rgba(248, 249, 250, 0.1);
  border-top: 1px solid rgba(222, 226, 230, 0.3);
}

.footer ul {
  list-style-type: none;
  padding: 0;
}

.footer li {
  margin-bottom: 10px;
  font-size: 0.9em;
  line-height: 1.4;
}

/* Responsive design */
@media (max-width: 768px) {
  .surveyList {
    flex-direction: column;
  }

  .surveyButton {
    width: 100%;
    margin-bottom: 10px;
  }

  .versionInfo {
    font-size: 0.9em;
    padding: 8px;
  }

  .footer {
    padding: 15px;
  }

  .footer li {
    font-size: 0.8em;
  }
}