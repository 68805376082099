.content {
    text-align: center;
  }
  
  .title {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: white;
  }
  
  .subtitle {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    color: white;
  }
  
  .buttonContainer {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
  
  .button {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: rgba(255, 255, 255, 0.2);
    border: 2px solid white;
    border-radius: 25px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
  
  @media (max-width: 768px) {
    .title {
      font-size: 2rem;
    }
  
    .subtitle {
      font-size: 1rem;
    }
  
    .buttonContainer {
      flex-direction: column;
    }
  }