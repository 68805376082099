.stripeCheckoutContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f0f0;
    color: #333;
}

.title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.instruction {
    margin-bottom: 1rem;
}

.checkoutButton {
    padding: 10px 20px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.errorMessage {
    color: red;
    margin-top: 1rem;
}