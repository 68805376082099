.signInContainer {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
}

.input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.error {
  color: #ff6b6b;
  margin-bottom: 10px;
}

.signUpLink {
  margin-top: 20px;
  text-align: center;
  color: white;
}

.signUpLink a {
  color: #4ecdc4;
  text-decoration: none;
}

.signUpLink a:hover {
  text-decoration: underline;
}