.welcomeDashboard {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .welcomeDashboard h1 {
    color: #333;
    margin-bottom: 20px;
  }
  
  .welcomeDashboard ol {
    text-align: left;
    margin: 20px 0;
    padding-left: 20px;
  }
  
  .welcomeDashboard li {
    margin-bottom: 10px;
  }