body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: white;
  margin: 0;
  padding: 0;
  background-color: #2c3e50; /* Dark blue background */
}

.container {
  width: 80%;
  max-width: 1200px;
  margin: auto;
  overflow: hidden;
  padding: 0 20px;
}

h1, h2, h3 {
  color: #ecf0f1;
}

a {
  color: #3498db;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #2980b9;
}

button {
  background-color: #3498db;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #2980b9;
}

/* Add some global utility classes */
.text-center {
  text-align: center;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}